<template>
  <div>
    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button @click="refresh()" class="btn btn-info">
          <i class="bi bi-arrow-clockwise"></i>Actualiser
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Nom Complet</th>
          <th scope="col">Nº Téléphone</th>
          <th scope="col">Addresse</th>
          <th scope="col">Date</th>
          <th scope="col">Montant Total</th>
          <th scope="col">Total Quantité</th>

          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(order, index) in orders" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            {{ order.reference }}
          </td>
          <td>
            <span v-if="order.fullName">
              {{ order.fullName }}
            </span>
          </td>

          <td>
            <span v-if="order.phone">
              {{ order.phone }}
            </span>
          </td>

          <td>
            <span v-if="order.address">
              {{ order.address }}
            </span>
          </td>
          <td>{{ order.dateTime }}</td>
          <td>
            <span v-if="order.totalPriceTTC != null" >
              {{ parseFloat(order.totalPriceTTC).toFixed(2) }}
            </span>
            DH
          </td>
          <td>
            <span v-if="order.totalQuantity != null" >
              {{ order.totalQuantity }}
            </span>
          </td>

          
          <td class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'oujda-food-orders-details',
                  params: { reference: order.reference },
                })
              "
              class="btn p-0 m-0"
            >
              <i class="bi bi-info-square p-0 m-0"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("order", {
      orders: "getAll",
    }),
  },
  mounted() {
    this.$store.dispatch("order/getAllOujdaFood");

    // setInterval(() => {
    //   this.$store.dispatch("order/getAll");
    // }, 3000);
  },
  methods: {
    async search(value) {
      return await this.$store.commit("order/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("order/getAllOujdaFood");
    },

    async listOrders(checkbox) {
      if (checkbox == true) {
        console.log(checkbox);
        return await this.$store.commit("order/getTodays");
      } else {
        return await this.$store.dispatch("order/getOrders");
      }
    },
    async sort(value) {
      return await this.$store.commit("order/sort", value);
    },
  },
};
</script>
